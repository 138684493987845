/* 
@font-face {
  font-family: AltoneTrial;
  src: url(/public/AltoneTrial-Regular.ttf);
} */


body {
  background-color: #15255a!important;
  background-image: url("/public/images/background.png");
  zoom:60%;
}

.btn-primary {
  background-color: #eb3496!important;    
  border-radius: 50px!important;
  font-weight: bold!important;
  font-size: 25px!important;
}


@media only screen
and (min-width : 768px) {

  .btn-primary {
    width: 250px!important;
    height: 75px!important;
  }
  


  .footer {
    color: #eb3496;
    font-size:15px;
  }


  
}

.logo {
  width:500px;
  margin-top:80px!important;
}


.container-fluid {
  max-width: 1900px!important;
}

.footer {
  color: #eb3496;
  font-size:25px;
}

.total {
  font-size:100px;
}

.social-icon {
  max-width: 60px;
}